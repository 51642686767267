/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url("fonts/open-sans-v15-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("fonts/open-sans-v15-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-800 - latin */
@font-face {
  font-family: "Open Sans Bold";
  font-style: normal;
  font-weight: 800;
  src: local("Open Sans ExtraBold"), local("OpenSans-ExtraBold"),
    url("fonts/open-sans-v15-latin-800.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("fonts/open-sans-v15-latin-800.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

body {
  font-family: sans-serif;
}
.language {
  padding: 0.5em;
  white-space: nowrap;
  position: absolute;
  right: 1em;
  top: 0;
  z-index: 11111;
}
.page-header {
  height: 21vh;
}
.page {
  height: 100vh;
  overflow: hidden;
}
.language img {
  height: 13vh;
  padding: 2px;
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 30%);
  border-radius: 20%;
  background-color: #dadada;
  margin: 5px;
  border: solid 2px #b2b2b2;
}
.language img:hover {
  border-radius: 20%;
  border-color: #7c7c7c;
}
.language img:active {
  position: relative;
  left: 5px;
  top: 5px;
  box-shadow: inset 1px 1px 11px 0px rgb(0 0 0 / 20%);
}
.input-patientid {
  text-align: center;
}
.keyboard {
  padding-top: 1em;
  margin-top: 1em;
  padding-right: 0.5em;
  overflow: hidden;
  position: absolute;
  width: 100%;
  top: 50vh;
}
.keyboard-row {
  margin-bottom: 1vh;
}
.keyboard-row .disabled.button {
  background: transparent;
  color: transparent;
}
.keyboard-row.left {
  text-align: left;
}
.keyboard-row.right {
  text-align: right;
}
.keyboard .button {
  font-size: 1.68vw !important;
  transition: .2s ease-in-out;
  box-shadow: -6px -6px 14px rgb(255 255 255 / 70%), -6px -6px 10px rgb(255 255 255 / 50%), 6px 6px 8px rgb(255 255 255 / 8%), 6px 6px 10px rgb(0 0 0 / 15%);
  background: transparent;
  width: 6vw;
  padding: 0;
  height: 6vw;
}
.keyboard .button:hover {
  box-shadow: -2px -2px 6px rgb(255 255 255 / 60%), -2px -2px 4px rgb(255 255 255 / 40%), 2px 2px 2px rgb(255 255 255 / 5%), 2px 2px 4px rgb(0 0 0 / 10%);
  background: #e9e9e9;
}
.keyboard .button:active {
  position: relative;
  left: 5px;
  top: 5px;
  box-shadow: inset -2px -2px 6px rgb(255 255 255 / 70%), inset -2px -2px 4px rgb(255 255 255 / 50%), inset 2px 2px 2px rgb(255 255 255 / 8%), inset 2px 2px 4px rgb(0 0 0 / 15%) !important;
  background: #e9e9e9;
  position: relative;
  left: 5px;
  top: 5px;
}
.keyboard .button:focus {
  background: #e9e9e9;
}

.keyboard-ent-buttons {
  float: right;
  width: 6vw;
  margin-right: 0.6vw;
}
.keyboard-backspace-button {
  width: 6vw;
}
.keyboard-enter-button {
  width: 6vw;
  height: 9.2vw;
  position: relative;
}
.keyboard-enter-button:before {
  content: "↵";
  position: absolute;
  top: 2em;
}
.keyboard-enter-button:after {
  content: "";
  height: 4.7vw;
  width: 1.3vw;
  border-top-right-radius: 0.5em;
  position: absolute;
  left: 0;
}
.keyboard-space {
  width: 50vw;
  color: transparent;
}
.keyboard-title {
  font-size: 3vw;
  line-height: 2em;
}
.keyboard-numbers {
  float: right;
}
.patientid-textbox {
  width: 55vw;
}
.patientid-day-textbox {
  width: 12vw;
  padding-left: 1vw;
  padding-right: 1vw;
  margin-right: 0;
}
.patientid-month-textbox {
  width: 12vw;
  padding-left: 1vw;
  padding-right: 1vw;
  margin-right: 0;
  margin-left: -2vw;
}
.patientid-year-textbox {
  width: 12vw;
  padding-left: 1vw;
  padding-right: 1vw;
  margin-left: -2vw;
}
.patientid-middlechar-textbox {
  width: 9vw;
  padding-left: 1vw;
  padding-right: 1vw;
  margin-left: -2vw;
}

.patientid-end-textbox {
  width: 18vw;
  padding-left: 1vw;
  padding-right: 1vw;
  margin-left: -2vw;
}
.keyboard-inputs {
  white-space: nowrap;
}
.keyboard-inputs form {
  padding-left: 0;
}
.keyboard-inputs input {
  text-align: center !important;
  padding-left: 1vw !important;
  padding-right: 1vw !important;
}

.keyboard-inputs,
.keyboard-inputs * {
  font-size: 2.4vw !important;
  line-height: 1em !important;
  padding: 0.5em;
}
.welcome {
  font-size: 8vw;
  line-height: 1em;
}
.logo {
  height: 15vh;
  max-width: 25vw !important;
  padding-left: 1vw;
  padding-top: 1vh;
}
.clock-time {
  font-size: 8vh;
  line-height: 2em;
  white-space: nowrap;
}
.nextappointment {
  font-size: 5vh;
  line-height: 1.5em;
  padding-left: 2%;
  letter-spacing: 3px;
}
.nextappointment-title {
  font-weight: bold;
  font-size: 0.8em;
  line-height: 1.5em;
  margin-bottom: 0.2em;
  max-height: 2em;
  overflow: hidden;
}
.nextappointment-desc {
  word-break: break-all;
  line-height: 1.2em;
  margin-bottom: 0.2em;
  max-height: 2.6em;
  overflow: hidden;
}
.nextappointment-time {
  font-weight: bold;
}
.nextappointment-checkintime {
  letter-spacing: 3px;
}
.nextappointment-checkintime .ui.loader {
  margin-top: -10px;
}
.nextappointment-loading {
  font-size: 4em;
  line-height: 1.5em;
  padding-left: 2%;
  letter-spacing: 3px;
  letter-spacing: 3px;
}
.nextappointment-loading .ui.loader {
  margin-top: -10px;
}
.laterappointment {
  font-size: 5vh;
  line-height: 1.5em;
  padding-left: 2%;
  padding-top: 2%;
}
.laterappointment-title {
  font-weight: bold;
  font-size: 0.8em;
  line-height: 1.5em;
  margin-bottom: 0.2em;
}
.laterappointment-desc {
  word-break: break-all;
  line-height: 1.2em;
  margin-bottom: 0.2em;
  max-height: 2.6em;
  overflow: hidden;
}
.laterappointment-time {
  font-weight: bold;
}
.patient {
  font-size: 4vh;
  background: white;
  line-height: 1.5em;
  border-radius: 10px;
  margin-right: 0.3em;
  box-shadow: 1px 1px 2px 0 rgb(0 0 0 / 30%);
  padding: 0.5em;
}
.patient-title {
  max-height: 2.4em;
  line-height: 1.2em;
  overflow: hidden;
}
.patient-address {
  line-height: 1.3em;
  font-size: 0.8em;
  font-weight: bold;
  max-height: 3.8em;
  overflow: hidden;
}
.patient-phone {
  margin-top: 0.4em;
  line-height: 1.2em;
  font-size: 0.8em;
  font-weight: bold;
}
.nextofkin {
  font-size: 4vh;
  line-height: 1.5em;
  padding-left: 0.5em;
}
.nextofkin-title {
  max-height: 2.4em;
  line-height: 1.2em;
  overflow: hidden;
}
.nextofkin-text-title {
  font-size: 0.7em;
  line-height: 1em;
  margin-top: 1.5em;
}
.nextofkin-address {
  line-height: 1.3em;
  font-size: 0.8em;
  font-weight: bold;
  max-height: 3.8em;
  overflow: hidden;
}
.nextofkin-phone {
  margin-top: 0.4em;
  line-height: 1.2em;
  font-size: 0.8em;
  font-weight: bold;
}
.notifychanges {
  margin-top: 0.8em;
  font-size: 4.5vh;
  line-height: 1.2em;
  padding-left: 0.5em;
}
.clock-icon {
  margin-top: 0.8em;
  margin-left: 0.8em;
  font-size: 1.5vh;
}
.location-icon {
  margin-top: 0.8em;
  margin-left: 0.8em;
  font-size: 1.5vh;
}
.info-icon {
  margin-top: 0em;
  margin-left: 0.8em;
  font-size: 1.5vh;
}
.successappointment {
  font-size: 4vh;
  line-height: 1.5em;
  letter-spacing: 3px;
  margin-left: 10px;
}
.info {
  font-size: 4vh;
  line-height: 1.5em;
  margin-left: 10px;
}
.successappointment-room {
  font-size: 5vh;
  font-weight: bold;
  line-height: 1.2em;
  max-height: 3.8em;
  overflow: hidden;
  margin-left: 10px;
  margin-right: 0.1em;
}
.successappointment-time {
  font-size: 5vh;
  font-weight: bold;
  line-height: 100%;
}
.successappointment-desc {
  word-break: break-all;
  line-height: 1.2em;
  font-weight: normal;
  margin-bottom: 0;
  max-height: 3.8em;
  overflow: hidden;
  margin-right: 0.1em;
}
.info-desc {
  word-break: break-all;
  line-height: 1.3em;
  font-weight: bold;
  max-height: 5.4em;
  overflow: hidden;
  margin-right: 0.1em;
}
.success-thankyou {
  font-size: 4vw;
  line-height: 1em;
  white-space: nowrap;
  margin-left: 10px;
}

.message-box {
  font-size: 4vh;
}

.newappointment-box {
  font-size: 5vh;
}
.newappointment-box .button {
  font-size: 5vh !important;
}
.newappointment-box-content {
  margin-top: 2vh;
  text-align: right;
  white-space: nowrap;
}

.back-button {
  position: absolute;
  right: 0em;
  bottom: 1em;
}
.back-button .button {
  font-size: 4vh !important;
}
.welcome-id-card-align {
  position: relative;
  top: -10vh;
}
.id-card-button {
  position: absolute;
  right: 0em;
  bottom: 1em;
  white-space: nowrap;
}
.id-card-button .button {
  font-size: 4vh !important;
}
.admin-error-message {
  margin: 1em;
  left: 10%;
  position: fixed;
  bottom: 1em;
  z-index: 1000;
  width: 80%;
}

.column {
  transition: width 0.3s;
}
.admin-settings-list {
  font-size: 0.9em;
}
.admin-logged-in-usr {
  position: absolute;
  top: 0.8em;
  left: 1.5em;
  z-index: 999;
}
.admin-logged-in-usr button {
  max-width: 8em;
  overflow: hidden;
  white-space: nowrap;
}
.adming-settings-value {
  word-wrap: break-word;
  max-height: 5.9em;
  overflow: auto;
  text-overflow: ellipsis;
}
.admin-logs-grid,
.admin-logs-grid .ui.form textarea {
  font-size: 0.9em;
}
.admin-logs-sticky {
  position: sticky !important;
  top: 0px !important;
  z-index: 1;
}
.admin-logs-row{
  border-bottom: solid 1px rgb(224, 224, 224);
}
.admin-logs-row .column{
  word-break:break-all;
}
.admin-form-sticky, .ui.segment.admin-form-sticky{
  position: sticky;
  top:1em;
}
.admin-log-message-text{
  width: 100%;
  min-height: auto;
  max-height: 20em;
  border: solid 1px lightgray;
  background: #fdfdfd;
  padding:0.5em;
  overflow-x: auto;
  white-space: pre;
}
.ui.admin-logs-grid > .row > .column,
.ui.admin-logs-grid > .row {
  padding: 2px !important;
  margin-top: 2px !important;
  margin-bottom: 2px !important;
  word-wrap: break-word;
}
.ui.fullscreen.modal.transition.visible.active.admin-fullscreen-modal,
.ui.fullscreen.scrolling.modal.transition.visible.active.admin-fullscreen-modal {
  left: auto !important;
}
.admin-section-title{
  position: absolute;
  right: 0;
  top: -110px;
  z-index: -1;
}
.admin-location-selected, .ui.segment.admin-location-selected{
  position: absolute;
  top: 0.5em;
  left: 25vw;
  width: 25vw;
  z-index: 1;
  height: 4em;
  margin: 0;
}
.admin-screen-selected, .ui.segment.admin-screen-selected{
  position: absolute;
  top: 0.5em;
  left: 50vw;
  width: 25vw;
  z-index: 1;
  height: 4em;
  margin: 0;
}
.admin-logview-textarea{
  height:80vh;
  min-height: auto;
  width: 100%;
  background: #fafafa;
}
.welcome-grid {
  height: 82vh;
}
.info-grid {
  height: 82vh;
}
.success-grid {
  height: 82vh;
}
.message-grid {
  height: 82vh;
}
.info-image {
  position: absolute;
  right: 0.5em;
  top: 18vh;
  height: 68vh;
  width: 25vw;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: center;
}
.info-image img {
  max-width: 100%;
  max-height: 68vh;
  box-shadow: -1px 1px 3px 0px rgb(0 0 0 / 40%);
  border-radius: 5px;
}
.box-shadow-avatar {
  box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 50%);
}
.success-container{
  display: flex;
  align-items: center;
  height:80vh;
}

.select-frontpage{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: 80vh;
}
.select-frontpage .header {
  font-size: 4vh !important;
  width: 100vw;
  text-align: center;
}
.select-frontpage .button {
  font-size: 3vh !important;
  width: 70vw;
  height: 10vh;
  text-align: left;
}

.selected-function-text {
  font-size: 5vw;
    line-height: 110%;
}


@media screen and (orientation: portrait) {
  .ui.grid > .stackable.stackable.row > .column,
  .ui.stackable.grid > .column.grid > .column,
  .ui.stackable.grid > .column.row > .column,
  .ui.stackable.grid > .column:not(.row),
  .ui.stackable.grid > .row > .column,
  .ui.stackable.grid > .row > .wide.column,
  .ui.stackable.grid > .wide.column {
    width: 100% !important;
    margin: 0 0 !important;
    box-shadow: none !important;
    padding: 1rem 1rem !important;
  }
  .keyboard-title {
    margin-top: 5vh;
  }
  .language img {
    height: 6vh;
  }
  .logo {
    max-width: 50vw !important;
    margin-left: 1vw;
    height: 6vh;
  }
  .clock-time {
    position: absolute;
    top: 5vh;
    left: -17vw;
    width: 100vw;
    font-size: 10vw;
  }
  .welcome {
    font-size: 12vw;
    line-height: 1.3em;
    margin-top: 10vh;
  }
  .nextappointment-checkintime .ui.loader {
    height: 2.7vh;
    margin-right: 0.2em;
    
  }
  .nextappointment-loading {
    font-size: 6vw;
    line-height: 1.5em;
    padding-left: 2%;
    letter-spacing: 3px;
    margin-top: 20vh;
    letter-spacing: 2px;
  }
  .nextappointment-desc {
    letter-spacing: normal;
  }
 
  .info-grid .nextappointment, .info-grid .laterappointment {
    font-size: 3vh !important;
    line-height: 1em;
    margin-top: 1vh;
  }
  .nextappointment-checkintime {
    white-space: nowrap;
    letter-spacing: normal;
    margin-top: 1em;
  }
  
  .info-grid .patient {
    display: inline-block;
    font-size: 2.2vh !important;
    margin-top: -2em;
    margin-left: 2%;
  }
  .info-grid .nextofkin {
    font-size: 2.2vh !important;
    margin-top: 0;
    margin-left: 0.3em;
  }
  .notifychanges {
    font-size: 2.5vh;
    margin-left:0.3em;
  }
  .id-card-button .button {
    font-size: 2.2vh !important;
  }
  .back-button {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 0.5em;
    font-size: 1.5em;
  }
  .back-button .button {
    padding: 0.5em !important;
    font-size: 2vh !important;
  }
  .ui.grid.success-grid {
    margin: 0.1em;
    margin-top: 0vh;
  }
  .successappointment {
    margin-top: 0;
    margin-left: 0;
    font-size: 4vh;
  }
  .successappointment-room {
    margin-left: 0;
    font-size: 3.2vh;
    line-height: 120%;
  }
  .successappointment-time{
    font-size: 3vh;
  }
  .success-grid {
    height: auto;
  }
  .successappointment-desc {
    font-size: 0.7em;
    line-height: 1.2em;
    letter-spacing: normal;
  }
  .info-desc {
    font-size: 0.7em;
    line-height: 1.2em;
    letter-spacing: normal;
    margin-right: 0.1em;
    max-height: 6.1em;
  }
  .info {
    margin-left: 0;
  }
  .success-thankyou {
    font-size: 6.8vw;
    margin-left: 0;
  }
 
  .newappointment-box {
    font-size: 5vw !important;
  }
  .newappointment-box-content {
    white-space: normal;
  }
  .newappointment-box .button {
    font-size: 6vw !important;
    margin-top: 1vh;
    width: 100%;
  }

  .keyboard .button {
    height: 4em;
  }
  .message-box {
    font-size: 3vh;
  }
  .info-icon, .clock-icon, .location-icon{
    font-size: 1vh;
  }
  .info-image {
    height: auto;
    position: static;
    max-height: 15vh;
  }

  
  .select-frontpage .button {
    font-size: 2.1vh !important;
  }
    
  .selected-function-text {
    margin-top: -2em; 
  }
}





.welcome {
  background: linear-gradient( 271deg, hsl(0deg 100% 63%), hsl(0deg 0% 17%), hsl(13deg 100% 58%) ) 0 0 / 400% 100%;
  color: transparent;
  background-clip: text;
}

@media (prefers-reduced-motion: no-preference) {
  .welcome {
    animation: move-bg 8s linear infinite;
  }
  @keyframes move-bg {
    to {
      background-position: 400% 0;
    }
  }
}
